














































































































































































































































































































































import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { Permissions } from "@/mixins/permissions";
import { CustomDate } from "@/mixins/custom-date";
import PageTitle from "@/components/General/PageTitle.vue";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import { Notification } from "@/models/notification.interface";
import { StyleCheck } from "@/mixins/style-check";

@Component({
  components: { PageTitle, Loader, Empty },
})
export default class Sales extends mixins(
  FormValidations,
  Navigation,
  Permissions,
  CustomDate,
  StyleCheck
) {
  search = "";
  dialog = false;
  loader = false;
  loading = false;
  loadingDT = false;
  selectedSubscriptors = [];
  options = {};
  cancelBillID = null;
  loadingCB = false;
  cancelBillDialog = false;

  pagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  @Watch("options", { deep: true })
  private async setItems(pagination) {
    this.loadingDT = true;
    this.pagination.itemsPerPage = pagination.itemsPerPage;

    await this.getBills(
      pagination.page,
      pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage
    );
    this.loadingDT = false;
  }

  private async getBills(
    page: number,
    size: number,
    bill_id = ""
  ): Promise<void> {
    await this.$store
      .dispatch("bill/getBills", { page, size, bill_id, operation: "minus" })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.s-e1"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private async created() {
    this.loader = true;
    await this.getBills(this.pagination.page, this.pagination.itemsPerPage);
    this.loader = false;
  }

  private get headers() {
    return [
      {
        text: this.$tc("Views.s-h1"),
        value: "client",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.s-h2"),
        value: "amount",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.s-h3"),
        value: "date",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.s-h4"),
        value: "payments",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.s-h5"),
        value: "status",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Products.headers.actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
    ];
  }

  private get bills(): any[] {
    let response = this.$store.getters["bill/getBills"];
    this.pagination.totalItems = response.totalItems;
    this.pagination.page = parseInt(response.currentPage);

    if (response?.bills) {
      return response?.bills;
    } else {
      return [];
    }
  }

  private billDetail(bill_id: string) {
    this.navigate("/staff/investments/detail/" + bill_id);
  }

  private checkBillUser(bill_id: string) {
    this.navigate("/staff/investments/check-bill-user/" + bill_id);
  }

  private paymentValidation(bill_id: string) {
    this.navigate("/staff/investments/payment-validation/" + bill_id);
  }

  private billHavePaymentsForValidation(bill: any): boolean {
    let response = false;

    bill.payments_cryptocurrency.forEach((payment) => {
      if (payment.status.name == this.$constants.STATUS.VALIDATING) {
        response = true;
      }
    });

    bill.payments_traditional.forEach((payment) => {
      if (payment.status.name == this.$constants.STATUS.VALIDATING) {
        response = true;
      }
    });

    return response;
  }

  private openCancelBillDialog(bill_id) {
    this.cancelBillID = bill_id;
    this.cancelBillDialog = true;
  }

  private async cancelBill() {
    this.loadingCB = true;
    await this.$store
      .dispatch("bill/updateBillStatus", {
        id: this.cancelBillID,
        status: this.$constants.STATUS.CANCELED,
      })
      .then(async () => {
        const success: Notification = {
          message: this.$tc("Views.sa-1"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: false,
        };

        this.$store.dispatch("notifications/showNotification", success);

        this.loader = true;
        await this.getBills(this.pagination.page, this.pagination.itemsPerPage);
        this.loader = false;
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.ukyc-e3"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
    this.cancelBillDialog = false;
    this.cancelBillID = null;
    this.loadingCB = false;
  }

  //Search by ID
  private async searchBillByID(clear: boolean) {
    this.loadingDT = true;
    if (this.search == null || clear === true) {
      this.search = "";
    }

    await this.getBills(
      this.pagination.page,
      this.pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }
}
